import Vue from 'vue'
import Vuex from 'vuex'
import { getToken } from '@/utils/auth'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		token: getToken(),
		name: '',
		avatar: '', // 头像
		roles: [], // 角色
	},
	getters: {
		token: state => state.token,
		avatar: state => state.avatar,
		name: state => state.name,
		roles: state => state.roles,
	},
	mutations: {
		SET_TOKEN: (state, token) => {
			state.token = token
		},
		SET_NAME: (state, name) => {
			state.name = name
		},
		SET_AVATAR: (state, avatar) => {
			state.avatar = avatar
		},
		SET_ROLES: (state, roles) => {
			state.roles = roles
		},
	},
	actions: {
		/* // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          const token = response.data
          setToken(token)
          commit('SET_TOKEN', token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    } */
	},
	modules: {},
})
